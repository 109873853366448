var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "裁剪图片",
        width: "790px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "append-to-body": true,
        visible: _vm.isShowCropper,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShowCropper = $event
        },
      },
    },
    [
      _c("div", { staticClass: "vue-cropper-box" }, [
        _c("div", { staticClass: "vue-cropper-content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "show-cropper",
              attrs: {
                "element-loading-text": "加载中",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _c("VueCropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.options.img,
                  "output-size": _vm.options.size,
                  "output-type": _vm.options.outputType,
                  info: _vm.options.info,
                  "center-box": _vm.options.centerBox,
                  "can-scale": _vm.options.canScale,
                  "auto-crop": _vm.options.autoCrop,
                  "auto-crop-width": _vm.options.autoCropWidth,
                  "auto-crop-height": _vm.options.autoCropHeight,
                  "fixed-box": _vm.options.fixedBox,
                  fixed: _vm.options.fixed,
                  "fixed-number": _vm.options.fixedNumber,
                  original: _vm.options.original,
                  full: _vm.options.full,
                },
                on: { imgLoad: _vm.imgLoad },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vue-cropper-bottom" }, [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.options.fixed,
                  expression: "!options.fixed",
                },
              ],
              staticClass: "vue-cropper-bottom-item",
            },
            [
              _vm._v(" 自定义尺寸："),
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-value": false,
                  "inactive-value": true,
                },
                model: {
                  value: _vm.options.fixedBox,
                  callback: function ($$v) {
                    _vm.$set(_vm.options, "fixedBox", $$v)
                  },
                  expression: "options.fixedBox",
                },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "vue-cropper-bottom-item" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "放大图片",
                    placement: "bottom",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-zoom-in scale",
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(1)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "vue-cropper-bottom-item" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "缩小图片",
                    placement: "bottom",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-zoom-out scale",
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(-1)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "vue-cropper-bottom-item" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "向左旋转90°",
                    placement: "bottom",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-refresh-left scale",
                    on: { click: _vm.rotateLeft },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.isShowCropper
            ? _c("el-button", { on: { click: _vm.cancelCubeImg } }, [
                _vm._v(" 取消 "),
              ])
            : _vm._e(),
          _vm.isShowCropper
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.isLoading,
                  },
                  on: { click: _vm.onCubeImg },
                },
                [_vm._v(" 确定 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }